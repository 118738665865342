.nav-bar {
	color: @text-dark;
	background-color: @color-ternary;
	border: 1px solid @color-grey-light;
	box-shadow: 0 0.3rem 0.7rem @color-grey-light;
	text-transform: uppercase;
	transition: all 250ms ease-in-out;

	&__inner {
		background-color: @color-ternary;
	}
}

.main-nav {
	&.active {
		.main-nav__list {
			max-height: 800px;
		}
	}

	&__list {
		display: flex;
		justify-content: center;
		align-items: center;
		transition: max-height 500ms ease;

		@media (max-width: 992px) {
			overflow: hidden;
		}

		.screen-lg( {
				flex-direction: column; margin-top: 5.5rem; max-height: 0;
			}

		);
	}

	&__item {
		text-align: center;

		&.dropdown {
			position: relative;
			display: inline-block;
			padding: 15px;
			cursor: pointer;

			@media (max-width: 992px) {
				display: none;
			}

			.nav__item-icon-wrapper {
				display: flex;
				align-items: center;

				.dropdown-icon {
					height: 8px;
					width: auto;
					margin-left: 5px;
				}
			}

			.dropdown-content {
				display: none;
				visibility: hidden;
				position: absolute;
				top: 42px;
				padding: 10px 7px;
				background-color: @color-ternary;
				z-index: 1;
			}

			&:hover .dropdown-content,
			.dropdown-content:hover {
				display: block;
				visibility: visible;
			}
		}

		&.sm-screen {
			display: none;

			@media (max-width: 992px) {
				display: block;
			}
		}

		// .screen-xxl( {
		//   &:nth-of-type(8) {
		//     display: none;
		//   }
		// }

		// );

		// .screen-xl( {
		//   &:nth-of-type(7) {
		//     display: none;
		//   }
		// }

		// .screen-lg( {
		//   &:nth-of-type(7) {
		//     display: initial;
		//   }

		//   &:nth-of-type(8) {
		//     display: initial;
		//   }
		// }

		// );

		&.active {
			color: @color-primary;
		}

		&:last-of-type {
			.screen-lg( {
					margin-bottom: 2rem;
				}

			);
		}
	}

	&__link {
		position: relative;
		display: block;
		line-height: 1.5;
		padding: 1rem 1.5rem;
		font-size: 13px;

		.screen-xxl( {
				padding: 1rem;
			}

		);

		&.active {
			color: @color-primary;
		}

		&::after {
			content: '';
			position: absolute;
			display: block;
			width: 0;
			height: 2px;
			bottom: 0;
			left: 0;
			background-color: @color-secondary;
			transition: width 250ms ease-in-out;
		}

		&:hover,
		&:active,
		&:focus {
			color: @color-primary;
			background-color: initial;

			&::after {
				width: 100%;
			}
		}
	}
}

.sticky {
	position: fixed;
	top: 0;
	width: 100%;
	box-shadow: 0 0.3rem 0.7rem @color-grey;
	z-index: 100;
}

.nav-bar__toggle {
	padding: 0.8rem 1.6rem;
	color: @color-primary;
	border: 1px solid @color-primary;
	border-radius: 2px;
	background-color: transparent;
	float: right;
	display: none;
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;

	.screen-lg( {
			display: block;
		}

	);
}