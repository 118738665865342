.content-page__cta {
	background-color: @color4;
    padding: 50px 0;
    
    &.bg-white {
        background-color: #fff;
    }

	.cta__inner {
        padding-top: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		text-align: center;

		p {
			font-size: 2.5rem;
			text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: 700;
			margin: 0;
			margin-bottom: 2rem;
			line-height: 40px;
			text-align: center;

			@media (max-width: 576px) {
				font-size: 2rem;
				line-height: 38px;
			}

			span {
				color: #2c6f87;
				font-weight: 700;
				font-size: 4rem;

			}
		}

		.phone {
			color: #2c6f87;
			font-weight: 700;
			font-size: 3.3rem;
		}

	}
}