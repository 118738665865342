.input-wrapper {
	position: relative;
	margin-top: 1rem;
	margin-bottom: 1rem;

	&:last-of-type {
		margin-bottom: 2rem;
	}
}

.input {
	padding: 0.75rem 1.5rem;
	font-size: 1.4rem;
	background-color: @color-ternary;
	border: 1px solid @color-grey-light;

	&--quantity {
		width: 6rem;
		padding: 1rem;
	}

	&--checkbox {
		position: absolute;
		opacity: 0;
		z-index: -2;

		&:checked+.checkbox-indicator {
			background-color: @color-primary;
		}
	}
}

.checkbox-indicator {
	position: absolute;
	top: 50%;
	left: 0.25rem;
	transform: translateY(-50%);
	height: 1.5rem;
	width: 1.5rem;
	background: @color-ternary;
	border: 1px solid @color-grey;
	border-radius: 2px;
	transition: background-color 150ms ease;
	z-index: -1;

	&:hover {
		background-color: fade(@color-primary, 60%);
	}
}


.btn {
	padding: 1rem 2rem;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 1.2rem;
	border: 1px solid transparent;
	border-radius: 1px;
	box-shadow: 0 0.3rem 0.7rem @color-grey-light;
	transition: background-color 200ms ease, color 200ms ease;

	a {
		color: @color-white;
	}

	&-default {
		color: @color-white;
		background: @color-primary;

		&:hover,
		&:active,
		&:focus,
		&:hover:active,
		&:focus:active {
			color: @color-white;
			background-color: darken(@color-primary, 5%);
		}
	}

	&.disabled {
		&:focus {
			color: @color-white;
			background-color: @color2;
		}
	}

	&-secondary {
		color: @color-white;
		background-color: @color-secondary;

		&:hover {
			color: @color-white;
			background-color: darken(@color-secondary, 5%);
		}
	}

	&.disabled {
		&:focus {
			background: @color2;
		}
	}

	&-action {
		padding: 0.75rem 3.5rem;
		font-weight: 700;
		color: @color-primary;
		background-color: @color-white;
		border: 1px solid @color-primary;

		&:hover {
			color: @color-white;
			background-color: darken(@color-primary, 5%);
		}
	}

	&-outlined {
		padding: 0.5rem 2.5rem;
		font-weight: 400;
		color: @color-white;
		background-color: transparent;
		border: 1px solid @color-white;
		box-shadow: none;

		&:hover {
			color: @color-secondary;
			background-color: @color-white;
		}
	}

	&--full-width {
		width: 100%;
	}

	&--icon {
		span {
			margin-right: 0.5rem;
			font-weight: 700;
		}
	}

	&--icon-right {
		span {
			margin-left: 0.5rem;
			font-weight: 700;
		}
	}

	&-decrement,
	&-increment {
		padding: 1rem;
		margin: 0.25rem;
	}

	&--add-to-cart {
		margin-left: 1rem;
	}
}

.alert {
	&-info {
		color: @color-white--dimmed;
		background-color: @color-dark;
		border-color: @color-white--dimmed-more;
	}
}

.return-to-top {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	bottom: 40px;
	right: 40px;
	height: 40px;
	width: 40px;
	background-color: @color-primary;
	border-radius: 50%;
	opacity: 0;
	visibility: hidden;
	z-index: 444;
	transition: background-color 300ms ease, opacity 300ms ease, visibility 300ms ease;

	.screen-md( {
			bottom: 25px;
			right: 20px;
		}

	);

	img {
		width: 70%;
	}

	&:hover {
		background-color: @color1;
	}

	&.active {
		opacity: 1;
		visibility: visible;
	}
}

.select {
	padding: 1rem;
	background-color: @color-ternary;
	text-transform: uppercase;
	font-size: 1.4rem;
	border: 1px solid @color-grey-light;

	&--styled {
		width: 50%;
		min-width: 250px;
	}
}

.select-wrapper {
	display: flex;
	align-items: center;
	margin-top: 0.5rem;

	.screen-md( {
			align-items: flex-start;
			flex-direction: column;

		}

	);
}

.label {
	margin-bottom: 0.5rem;
	padding: 0;
	color: inherit;
	font-size: 1.4rem;
	text-align: left;

	&--filter {
		padding-left: 2rem;
		font-weight: 400;
	}

	&--styled {
		width: 35%;
		min-width: 250px;
		font-size: 1.4rem;

		.screen-xl( {
				min-width: unset;
			}

		)
	}
}