.client-panel {
  display: flex;
  justify-content: flex-end;

  &--navbar {
    float: right;
    margin-top: .8rem;
    margin-right: 1.6rem;
    opacity: 0;
    visibility: hidden;
    transition: opacity 400ms ease-in, visibility 400ms ease-in;

    &.visible {
      opacity: 1;
      visibility: visible;
    }
  }

  &__item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
    border: 1px solid @color-grey-light;
    background-color: @color-white;
    box-shadow: 0 0.3rem 0.7rem @color-grey-light;

    &--navbar {
      background-color: transparent;
    }

    &--cart {
      &:after {
        content: attr(data-quantity);
        position: absolute;
        top: -0.5rem;
        right: -0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
        height: 2rem;
        width: 2rem;
        font-size: 1.2rem;
        border-radius: 50%;
        background-color: @color-ternary;
        box-shadow: 0 0.3rem 0.3rem @color-grey;
      }
    }
  }

  &__item-desc {
    padding: 0 1rem;
    font-size: 1.2rem;

    &--navbar {
      display: none;
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;

    // &--navbar {}
  }

  &__icon {
    display: block;
    padding: 1.5rem;
    width: 5rem;
    color: @color-white;
    border-radius: 2px;
    background-color: @color-primary;

    .screen-sm( {
      padding: 1rem;
      width: 3.5rem;
    }

    );

    &--navbar {
      padding: .5rem;
      width: 3.25rem;
      height: 3.25rem;

    }
  }

//   &__cart-indicator {
//     &--navbar {}
//   }
}

.account-link {
  .screen-sm( {
    display: none;
  }

  );

  .screen-custom(359px, {
    display: initial;
  }

  );
}