.products {
  &__inner {
    display: flex;
    justify-content: space-between;

    .screen-md( {
      flex-direction: column;
    }

    );
  }

  &__sidebar {
    width: 20%;
    min-width: 225px;
    margin-right: 1rem;


    .screen-md( {
      width: 100%;
    }

    );
  }

  .filtering-toggle {
    display: none;

    img {
      width: 1.5rem;
      margin-left: .5rem;
    }

    .screen-md( {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    );
  }

  &__sidebar-content {
    transition: max-height 750ms ease-in-out;

    .screen-md( {
      max-height: 0;
      overflow: hidden;
    }

    );
  }

  &__sidebar-content.active {

    .screen-md( {
      max-height: 650px;
      overflow-y: auto;
    }

    );
  }

  &__sidebar-item {
    margin-top: 2rem;
  }

  &__content {
    width: 75%;

    .screen-md( {
      width: 100%;
    }

    );
  }
}

.sorting {
  width: 100%;
  display: flex;
  padding: 1rem 0;

  &__title {
    margin-right: 2rem;

    .screen-md( {
      display: none;
    }

    );
  }

  &__option {
    padding: 0 1rem;
    opacity: 0.5;

    &.active {
      opacity: 1;
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-right: 0.25rem;
      width: 1.5rem;
    }

    .screen-sm( {
      font-size: 1rem;
    }

    );
  }
}

.sidebar-categories {
  background-color: @color-ternary;
}

.filter {
  &__dimensions {
    .label {
      padding: 0.5rem 0.5rem 1rem 0;
    }

    .input {
      margin-top: .5rem;

      .screen-lg( {
        max-width: 200px;
      }

      );

      .screen-sm( {
        max-width: unset;
      }

      );
    }
  }

  &__dimension {}

  &__type {}
}

.filter-list {
  &__item {
    position: relative;
    padding: 0.5rem;
  }
}