.tabpanel {
	&__list {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		// display: none;

		.screen-xl( {
				.product-card--four-grid:nth-of-type(4) {
					display: none;
				}
			}

		);

		.screen-lg( {
				.product-card--four-grid:nth-of-type(4) {
					display: flex;
				}
			}

		);
	}
}

@media(max-width: 576px) {
	.tab-content {
		height: 100vh;
	}
}

.tabs-promo__heading {
	margin-bottom: 2rem;
	font-size: 3rem;
	font-weight: 700;
	text-align: center;
}

.nav.nav-tabs {
	text-align: center;
	border-bottom: none;

	li {
		float: none;
		display: inline-block;

		&.active {
			a {
				color: @color-secondary;
				border: none;
			}
		}

		a {
			font-size: 1.6rem;

			.on-event( {
					color: @color-secondary;
					background-color: initial;
					border-color: transparent;
				}

			);
		}
	}
}