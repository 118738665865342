.container-fluid {
  width: 100%;
  max-width: 1366px;
  padding: 0 1rem;
  margin: 0 auto;
}

.section {
  padding: 3rem 0;
}

.centered {
  display: block;
  margin: 0 auto;
}