.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__main {
    position: relative;
    display: block;
    line-height: 1;
    font-size: 2.8rem;
    padding: 2px 1rem;
    font-family: @font-secondary;
    text-transform: uppercase;
    z-index: 100;
    overflow: hidden;

    .screen-md( {
      font-size: 2.25rem;
    }

    );

    .screen-sm( {
      font-size: 1.8rem;
    }

    );



  }

  &__sub {
    display: block;
    color: @color-white;
    padding: .5rem 1.5rem;
    font-size: 1.2rem;
    line-height: 1;
    font-family: @font-secondary;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 3px;
    font-weight: 700;
    line-height: 1;
    background-color: @color-primary;
    border: 1px solid @color-primary;
    margin-top: .25rem;
    border-radius: 2px;
    width: 100%;

    .screen-md( {
      font-size: 1rem;
    }

    );

    .screen-sm( {
      font-size: .75rem;
    }

    );
  }

  &:hover,
  &:focus,
  &:active {



    .logo__border {
      &--top {
        .keyframes(snake-horizontal;

          {
          0% {
            transform: scaleX(1);

          }

          50% {}

          100% {
            transform: scaleX(0);
            background-color: @color-primary;


          }
        }

        );

        .animation(snake-horizontal .25s ease-in-out forwards)
      }



      &--bottom {
        .keyframes(snake-horizontal;

          {
          0% {
            transform: scaleX(1)
          }

          50% {}

          100% {
            transform: scaleX(0);
            background-color: @color-primary;

          }
        }

        );

        .animation(snake-horizontal .25s ease-in-out forwards)
      }

      &--left {
        .keyframes(snake-vertical;

          {
          0% {
            transform: scaleY(0)
          }

          50% {}

          100% {
            transform: scaleY(1);
            background-color: @color-primary;

          }
        }

        );

        .animation(snake-vertical .25s ease-in-out forwards)
      }

      &--right {
        .keyframes(snake-vertical;

          {
          0% {
            transform: scaleY(0)
          }

          50% {}

          100% {
            transform: scaleY(1);
            background-color: @color-primary;

          }
        }

        );

        .animation(snake-vertical .25s ease-in-out forwards)
      }
    }


  }

  .logo__main {}




  &__border {
    position: absolute;
    display: block;
    background-color: @color-secondary;
    transition: all 200ms ease-in-out;

    &--top {
      top: 0;
      left: 0;
      height: 2px;
      width: 100%;
      transform-origin: 100% 0%;
      transform: scaleX(1);
      .keyframes(snake-horizontal-back;

        {
        0% {
          transform: scaleX(0)
        }

        50% {}

        100% {
          transform: scaleX(1)
        }
      }

      );

      .animation(snake-horizontal-back .25s ease-in-out forwards)
    }

    &--bottom {
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
      transform-origin: 0% 0%;
      transform: scaleX(1);
      .keyframes(snake-horizontal-back;

        {
        0% {
          transform: scaleX(0)
        }

        50% {}

        100% {
          transform: scaleX(1)
        }
      }

      );

      .animation(snake-horizontal-back .25s ease-in-out forwards)
    }

    &--left {
      bottom: 0;
      left: 0;
      height: 100%;
      width: 2px;
      transform-origin: 50% 100%;
      transform: scaleY(0);
      .keyframes(snake-vertical-back;

        {
        0% {
          transform: scaleY(1)
        }

        50% {}

        100% {
          transform: scaleY(0)
        }
      }

      );

      .animation(snake-vertical-back .25s ease-in-out forwards)
    }

    &--right {
      bottom: 0;
      right: 0;
      height: 100%;
      width: 2px;
      transform-origin: 100% 0%;
      transform: scaleY(0);
      .keyframes(snake-vertical-back;

        {
        0% {
          transform: scaleY(1)
        }

        50% {}

        100% {
          transform: scaleY(0)
        }
      }

      );

      .animation(snake-vertical-back .25s ease-in-out forwards)
    }

  }
}