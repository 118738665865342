.search-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0.5rem;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  @media (max-width: 576px) {
	  padding: 0;
  }

  .screen-sm( {
    flex-direction: column;
  }

  );

  &__input {
    padding: 1rem;
    margin-right: 1rem;
    background-color: @color-white;

    .screen-sm( {
      width: 100%;
      margin-right: 0;
      margin-bottom: .5rem;
    }

    );
  }

  &__button {
    padding: 0.75rem;
    font-weight: 400;

    img {
      margin-right: .5rem;
      width: 1.25rem;
      margin-top: -.5rem;
    }

    .screen-sm( {
      width: 100%;
    }

    );
  }

  .btn-icon {
    margin-left: 1rem;
  }
}