// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
// Application-wide Sass variables.
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
//  font variables
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

@import url('https://fonts.googleapis.com/css?family=Maven+Pro:400,700|Noto+Sans:400,700&subset=latin-ext');


@font-primary: 'Noto Sans', 'Roboto', serif;
@font-secondary: 'Maven Pro', 'Montserrat', sans-serif;
@Font-monospace: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace;

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
//  Colors variables
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

// palette
// @color1: #44657a;
@color1: #2c6f87;
@color2: #eccc3a;
@color3: #f62a66;
@color4: #fafafa;
@color5: #23292c;
// palette

@color-primary: @color1;
@color-secondary: @color2;
@color-ternary: @color4;
@color-danger: @color3;
@color-success: #00A478;

@color-grey: #838383;
@color-grey-darker: #616161;
@color-grey-light: lighten(#b1b1b1, 25%);

@text-dark: @color5;
@text-dark-light: lighten(#3d3b3c, 5%);
@text-white: #ffffff;
@text-white--dimmed: fade(#ffffff, 60%);

@color-white: #ffffff;
@color-dark: @color5;
@color-white--dimmed: fade(#ffffff, 60%);
@color-white--dimmed-more: fade(#ffffff, 5%);

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
//  Utilities variables
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
@assets-dir: 'assets/';
@images-dir: 'assets/images/';

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
//  Shadows
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
@shadow-tiny: 0 0.2rem 0.4rem;
@shadow-small: 0 0.3rem 0.7rem;
@shadow-medium: 0 0.5rem 1rem;
@shadow-large: 0 1rem 1.75rem;

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
//  Bootstrap breakpoints variables
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
@screen-xs-min: 320px;
@screen-sm-min: 576px;
@screen-md-min: 768px;
@screen-lg-min: 992px;
@screen-xl-min: 1200px;
@screen-xxl-min: 1366px;

.screen-xs(@rules) {
  @media only screen and (max-width: @screen-xs-min) {
    @rules();
  }
}

.screen-sm(@rules) {
  @media only screen and (max-width: @screen-sm-min) {
    @rules();
  }
}

.screen-md(@rules) {
  @media only screen and (max-width: @screen-md-min) {
    @rules();
  }
}

.screen-lg(@rules) {
  @media only screen and (max-width: @screen-lg-min) {
    @rules();
  }
}

.screen-xl(@rules) {
  @media only screen and (max-width: @screen-xl-min) {
    @rules();
  }
}

.screen-xxl(@rules) {
  @media only screen and (max-width: @screen-xxl-min) {
    @rules();
  }
}

.screen-custom(@width, @rules) {
  @media only screen and (max-width: @width) {
    @rules();
  }
}


.on-event(@rules) {

  &:hover,
  &:active,
  &:focus {
    @rules();
  }
}


// Animation keyframes
.keyframes(@name;

@arguments) {
  @-moz-keyframes @name {
    @arguments();
  }

  @-webkit-keyframes @name {
    @arguments();
  }

  @keyframes @name {
    @arguments();
  }
}

.animation(@arguments) {
  -webkit-animation: @arguments;
  -moz-animation: @arguments;
  animation: @arguments;
}