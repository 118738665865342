.input-number-decrement,
.input-number-increment {
  background-color: @color-primary;
}

.system-alerts {
  position: fixed;
  width: 100%;
  z-index: 225;
  top: 0;
}

@media screen and (max-width: 1100px) {
  .navbar li a {
    padding: 15px 10px 15px 10px;
  }
}

@media screen and (max-width: 1280px) {
  .navbar-nav li:nth-of-type(8) {
    display: none;
  }
}