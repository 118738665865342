.product-view {
	display: flex;
	justify-content: space-between;

	.screen-md( {
			flex-direction: column;
		}

	);

	&__gallery {
		width: 47.5%;
		flex-direction: column;

		.screen-md( {
				width: 80%; margin: 0 auto;
			}

		);
	}

	&__icons {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		padding-top: 20px;

		img {
			width: 70px;
			margin: 3px;
			cursor: pointer;

			&.hidden {
				visibility: hidden;
			}

			@media (max-width: 576px) {
				width: 50px;
			}
		}

		.tooltip.top .tooltip-inner {
			background-color: @color-primary;
			padding: 10px;
		}

		.tooltip.top .tooltip-arrow {
			border-top-color: @color-primary;
		}
	}

	&__base-info {
		width: 47.5%;

		.screen-md( {
				width: 100%;
			}

		);
	}

	&__details {
		display: flex;
		justify-content: space-between;
		min-height: 300px;
		padding: 3rem 0;
		border-top: 1px solid @color-grey-light;
		border-bottom: 1px solid @color-grey-light;

		.screen-md( {
				flex-direction: column;
			}

		);
	}
}

.product-view__heading {
	font-size: 2.25rem;
	font-weight: 700;

	&--small {
		font-size: 1.6rem;
	}
}

.product-header {
	margin: 2rem 0;

	&__product-name {
		font-size: 2.5rem;
		font-weight: 700;
	}

	&__details {
		display: flex;
		align-items: center;
		margin-top: 1.5rem;
	}

	&__code {
		margin-right: 2rem;
	}

	&__manufacturer {}
}

.product-attributes {
	margin-top: 2rem;

	&__list {
		margin-top: 0.5rem;
	}

	&__item {
		margin-top: 1.2rem;
		font-size: 1.4rem;
	}

	.circle-to-checked {
		display: flex;
		align-items: center;

		.circle {
			display: inline-block;
			width: 15px;
			height: 15px;
			border-radius: 50%;
			margin-right: 3px;

			&:last-of-type {
				margin-left: 0;
			}

			&.color {
				background: @color-primary;
			}

			&.empty {
				border: 1px solid @color-primary;
			}
		}
	}
}

.product-variants {
	margin-top: 2rem;
}

.price-wrapper {
	margin: 2rem 0;
	display: flex;
	align-items: center;
}

.price {
	font-size: 2.5rem;
	font-weight: 700;
	color: @color-primary;
	margin-left: 8px;

	@media (max-width: 576px) {
		font-size: 2.3rem;
	}

	&__label {
		font-size: 1.6rem;
	}

	&__currency {
		font-size: 1.8rem;
	}

	&__netto {
		font-size: 1.5rem;
		margin-left: 8px;

		span {
			font-size: 1.2rem;
		}
	}

	&__discounted-price {
		position: relative;
		font-size: 2rem;
		color: @color-grey;

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			height: 2px;
			width: 100%;
			background-color: @color-grey;
			transform-origin: 0 0;
			transform: translateY(-50%);
		}
	}

	&__currency-discounted {
		font-size: 1.5rem;
	}
}

.add-to-cart {
	display: flex;
	align-items: center;
	margin-top: 1rem;
}

.availibility {
	margin-top: 1rem;
	color: @color-grey;

	&__status {
		&.availible {
			color: @color-success;
		}

		&.not-availible {
			color: @color-danger;
		}
	}
}

.quantity-selector {
	display: flex;
	align-items: center;
}

.product-description {
	width: 70%;

	.screen-md( {
			width: 100%;
		}

	);

	&__content {
		margin-top: 2rem;
		line-height: 1.5;
	}
}

.product-aside {
	width: 26%;
	padding: 1rem;
	background-color: @color-ternary;
	border: 1px solid @color-grey-light;
	box-shadow: @shadow-small @color-grey-light;

	.screen-md( {
			width: 80%; margin: 0 auto; margin-top: 3rem;
		}

	);

	&__item {}

	&__header {}

	&__title {
		margin-bottom: 2rem;
		font-size: 1.8rem;
		text-align: center;
		text-transform: uppercase;
	}

	&__item-content {}
}

.product-aside__attachment {}

.attachment-list {
	list-style: none;

	&__item {}

	&__link {
		position: relative;
		display: block;
		margin-top: 1rem;
		margin-left: 3rem;
		color: @color-primary;

		&::before {
			content: '\21D3';
			position: absolute;
			top: 50%;
			left: -1.5rem;
			transform: translate(-50%, -50%);
			height: 2rem;
			width: 2rem;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 700;
			color: @color-white;
			background-color: @color-primary;
			border-radius: 50%;
		}
	}
}