.policy {
  &__inner {}

  &__content {
    width: 75%;

    .screen-md( {
      width: 100%;
    }

    );

    &--full-w {
      width: 100%;
    }
  }
}