.products-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  &.list-horizontal {
    .product-card {
      width: 100%;
      height: auto;
      flex-direction: row;


      &__image-wrapper {
        max-width: 300px;
        flex-shrink: 0;

        .screen-sm( {
          max-width: 150px;
          flex-shrink: 1;

        }

        );
      }

      &__image {}

      &__content {
        display: flex;

        .screen-xl( {
          justify-content: center;
          flex-direction: column;
          align-items: center;
        }

        );
      }

      &__header {
        width: 50%;

        .screen-xl( {
          width: 100%;
        }

        );
      }

      &__price-wrapper {
        width: 25%;

        .screen-xl( {
          width: 100%;
        }

        );
      }

      &__actions {
        width: 25%;

        .screen-xl( {
          width: 100%;
        }

        );

        .btn {
          .screen-xl( {
            margin: 0 auto;
          }

          );
        }
      }

      &__new-label {
        top: -2.5rem;
        right: unset;
        left: 0;

        .screen-sm( {
          top: -1.5rem;
          right: unset;
          left: -3.5rem;
        }

        );
      }

      &__promo-label {
        top: 12.5%;
        left: unset;
        right: 45%;
        transform: rotate(-37.5deg);

        .screen-sm( {
          top: 14.5%;
          left: unset;
          right: 40%;
        }

        );
      }
    }
  }
}

.products-view-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-list {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  margin-top: 2rem;
  flex-wrap: wrap;

  &__item {
    padding: .5rem 0.5rem;

    &.active {
      .pagination-list__link {
        color: @color-white;
        background-color: @color-primary;
      }
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 3rem;
    font-weight: 700;
    color: @color-primary;
    border: 1px solid @color-primary;
    border-radius: 50%;
    transition: all 250ms ease;

    &:hover {
      color: @color-white;
      background-color: @color-primary;
    }
  }
}

.grid-mode-switch {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-mode-grid,
.grid-mode-list {
  margin: 0 0.5rem;
  font-size: 1.75rem;
  background-color: transparent;
  border: none;
  border-radius: 2px;
  opacity: 0.25;

  &.active {
    opacity: 1;
  }
}