.section-heading {
  margin-bottom: 3rem;
  text-align: center;

  &--left {
    text-align: left;
  }

  &--margin-large {
    margin-bottom: 6rem;
  }

  &__title {
    font-size: 3rem;
    font-weight: 700;

    &--light {
      font-weight: 400;
      color: @text-white;
    }

    &--color {
        font-weight: 400;
        color: @color-primary;
      }

    &--small {
      font-size: 2.5rem;
    }
  }

  &__subtitle {
    margin-top: 1rem;
    font-size: 2rem;
  }
}