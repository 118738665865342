.recommended-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .screen-xl( {
    .product-card--four-grid:nth-of-type(4) {
      display: none;
    }
  }

  );

  .screen-lg( {
    .product-card--four-grid:nth-of-type(4) {
      display: flex;
    }
  }

  );
}