.facebook-plugin {
  position: fixed;
  right: -300px;
  height: 200px;
  top: 35%;
  z-index: 999;
  display: flex;
  align-items: center;
  transition: 0.4s;

  &:hover {
    right: 0;
  }

  .plugin-service {
    display: flex;
    align-items: center;

    .service-button {
      cursor: pointer;

      img {
        height: 45px;
      }
    }
  }
}

.section-footer {
  padding: 3rem 0;
  font-size: 1.2rem;
  color: @text-white--dimmed;
  background-color: @color5;

  &__inner {
    display: flex;
    padding: 2rem 0;

    .screen-lg( {
        justify-content: center;
        flex-wrap: wrap;
      }

    );
  }

  &__item {
    flex: 1;
    padding: 0 1rem;
    margin: 0 1rem 3rem;
    min-width: 200px;

    &:not(:last-of-type) {
      border-right: 1px solid @color-white--dimmed-more;

      .screen-xl( {
          border-right: 0px;
        }

      );
    }

    .screen-xl( {
        text-align: center;
      }

    );
  }

  &__heading {
    margin-bottom: 2rem;
    font-size: 1.4rem;
    font-weight: 700;
    color: @color-white;

    &:nth-of-type(2) {
      margin-top: 2rem;
    }

    .screen-sm( {
        font-size: 1.5rem;
      }

    );
  }

  &__list {
  }

  &__list-item {
    margin-top: 1rem;
  }

  &__link {
    color: @text-white--dimmed;

    .on-event( {
        color: @color-white;

      }

    );
  }

  &__address {
    p {
      margin-top: 0.5rem;
    }
  }
}
