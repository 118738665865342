// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
// Base styles
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

html {
  box-sizing: border-box;
}

body {
  font-family: @font-primary;
  font-weight: 400;
  color: @text-dark;
  line-height: 1.25;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
  outline-color: @color-primary;
}

a {
  color: inherit;
  text-decoration: none;
  transition: color 150ms ease;

  .on-event( {
    color: @color-primary;
  }

  );
}

img {
  max-width: 100%;
}