.content-page {
	&__inner {
		display: flex;
		justify-content: space-between;
		text-align: justify;

		&.flex-column {
			flex-direction: column;
		}

		.buttons-wrapper {
			padding: 25px 0;

			&.btn-next {
				display: flex;
				justify-content: flex-end;
			}

			&.btn-see-all {
				display: flex;
				justify-content: center;
			}

			&.btn-next-back {
				display: flex;
				justify-content: space-between;

				@media (max-width: 576px) {
					.btn {
						padding: 1rem;
					}
				}
			}
		}

		.screen-lg( {
				flex-direction: column;
			}

		);
	}

	&__content {
		width: 75%;

		@media (min-width: 576px) {
			&.pl-15 {
				padding-left: 15px;
			}
		}

		.screen-xxl( {
				width: 70%;
			}

		);

		.screen-lg( {
				width: 100%;
			}

		);
	}

	.content-page__recommended {
		display: flex;
		flex-direction: column;
		padding-top: 30px;

		.recommended-heading {
			margin-bottom: 3rem;
			text-align: center;

			h2 {
				font-size: 3rem;
				font-weight: 700;
				text-align: center;
			}

		}
	}

	&__aside {
        width: 20%;
        
        &.w-100 {
            width: 100%;
        }

		.screen-xxl( {
				width: 25%;
			}

		);

		.screen-lg( {
				width: 100%;
				margin-top: 6rem;
			}

		);
	}

	&__image-right {
		float: right;
		width: 55%;
		margin: 0 0 3rem 3rem;
		display: block;
		margin-bottom: 3rem;
		border-radius: 5px;

		.screen-sm( {
				width: 100%;
			}

		);
	}

	&__image-left {
		float: left;
		width: 45%;
		margin: 3rem 3rem 3rem 0;
		display: block;
		border-radius: 10px;

		.screen-md( {
				width: 65%;
			}

		);

		.screen-sm( {
				width: 100%;
			}

		);
	}

	p {
		margin-top: 2rem;
		line-height: 1.75;
	}
}