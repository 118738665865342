.contact {
  margin-bottom: 6rem;

  &__form-wrapper {
    margin-bottom: 3rem;
  }

  &__form {
    width: 70%;
    margin: 0 auto;

    .screen-md( {
      width: 90%;
    }

    );

    .label,
    .input {
      display: block;
      width: 100%;
    }

    .label {
      margin-bottom: 0;
      margin-top: 2rem;
      font-size: 1.2rem;
    }

    .btn {
      margin-top: 2rem;
    }
  }

  &__location {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;

    .screen-md( {
      flex-direction: column;
      margin-top: 6rem;

    }

    );
  }

  &__data {
    width: 30%;

    .screen-md( {
      width: 100%;
    }

    );
  }

  &__map-wrapper {
    width: 66%;

    .screen-md( {
      width: 100%;
      margin-top: 3rem;
    }

    );
  }

  &__map {
    width: 75%;
    height: 400px;
    border: none;

    .screen-md( {
      width: 90%;
    }

    );
  }

  &__title {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    color: @color-primary;
    text-transform: capitalize;

    &--sub {
      font-size: 1.8rem;
    }
  }

  &__address {
    margin-top: 2rem;
    font-size: 1.6rem;

    p {
      margin-top: 0.5rem;
    }
  }

  &__forms {
    margin: 2rem 0;
  }

  &__form {
    margin-top: 1rem;

    a {
      font-size: 1.6rem;
    }
  }
}