@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/mavenpro/v22/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8SX21nmjpw.ttf) format('truetype');
}
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/mavenpro/v22/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8cLx1nmjpw.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/notosans/v21/o-0IIpQlx3QUlC5A4PNr6zRF.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/notosans/v21/o-0NIpQlx3QUlC5A4PNjXhFVatyE.ttf) format('truetype');
}
html {
  box-sizing: border-box;
}
body {
  font-family: 'Noto Sans', 'Roboto', serif;
  font-weight: 400;
  color: #23292c;
  line-height: 1.25;
  overflow-x: hidden;
}
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
  outline-color: #2c6f87;
}
a {
  color: inherit;
  text-decoration: none;
  transition: color 150ms ease;
}
a:hover,
a:active,
a:focus {
  color: #2c6f87;
}
img {
  max-width: 100%;
}
.container-fluid {
  width: 100%;
  max-width: 1366px;
  padding: 0 1rem;
  margin: 0 auto;
}
.section {
  padding: 3rem 0;
}
.centered {
  display: block;
  margin: 0 auto;
}
.input-wrapper {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.input-wrapper:last-of-type {
  margin-bottom: 2rem;
}
.input {
  padding: 0.75rem 1.5rem;
  font-size: 1.4rem;
  background-color: #fafafa;
  border: 1px solid #f1f1f1;
}
.input--quantity {
  width: 6rem;
  padding: 1rem;
}
.input--checkbox {
  position: absolute;
  opacity: 0;
  z-index: -2;
}
.input--checkbox:checked + .checkbox-indicator {
  background-color: #2c6f87;
}
.checkbox-indicator {
  position: absolute;
  top: 50%;
  left: 0.25rem;
  transform: translateY(-50%);
  height: 1.5rem;
  width: 1.5rem;
  background: #fafafa;
  border: 1px solid #838383;
  border-radius: 2px;
  transition: background-color 150ms ease;
  z-index: -1;
}
.checkbox-indicator:hover {
  background-color: rgba(44, 111, 135, 0.6);
}
.btn {
  padding: 1rem 2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.2rem;
  border: 1px solid transparent;
  border-radius: 1px;
  box-shadow: 0 0.3rem 0.7rem #f1f1f1;
  transition: background-color 200ms ease, color 200ms ease;
}
.btn a {
  color: #ffffff;
}
.btn-default {
  color: #ffffff;
  background: #2c6f87;
}
.btn-default:hover,
.btn-default:active,
.btn-default:focus,
.btn-default:hover:active,
.btn-default:focus:active {
  color: #ffffff;
  background-color: #265f74;
}
.btn.disabled:focus {
  color: #ffffff;
  background-color: #eccc3a;
}
.btn-secondary {
  color: #ffffff;
  background-color: #eccc3a;
}
.btn-secondary:hover {
  color: #ffffff;
  background-color: #eac623;
}
.btn.disabled:focus {
  background: #eccc3a;
}
.btn-action {
  padding: 0.75rem 3.5rem;
  font-weight: 700;
  color: #2c6f87;
  background-color: #ffffff;
  border: 1px solid #2c6f87;
}
.btn-action:hover {
  color: #ffffff;
  background-color: #265f74;
}
.btn-outlined {
  padding: 0.5rem 2.5rem;
  font-weight: 400;
  color: #ffffff;
  background-color: transparent;
  border: 1px solid #ffffff;
  box-shadow: none;
}
.btn-outlined:hover {
  color: #eccc3a;
  background-color: #ffffff;
}
.btn--full-width {
  width: 100%;
}
.btn--icon span {
  margin-right: 0.5rem;
  font-weight: 700;
}
.btn--icon-right span {
  margin-left: 0.5rem;
  font-weight: 700;
}
.btn-decrement,
.btn-increment {
  padding: 1rem;
  margin: 0.25rem;
}
.btn--add-to-cart {
  margin-left: 1rem;
}
.alert-info {
  color: rgba(255, 255, 255, 0.6);
  background-color: #23292c;
  border-color: rgba(255, 255, 255, 0.05);
}
.return-to-top {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 40px;
  right: 40px;
  height: 40px;
  width: 40px;
  background-color: #2c6f87;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  z-index: 444;
  transition: background-color 300ms ease, opacity 300ms ease, visibility 300ms ease;
}
@media only screen and (max-width: 768px) {
  .return-to-top {
    bottom: 25px;
    right: 20px;
  }
}
.return-to-top img {
  width: 70%;
}
.return-to-top:hover {
  background-color: #2c6f87;
}
.return-to-top.active {
  opacity: 1;
  visibility: visible;
}
.select {
  padding: 1rem;
  background-color: #fafafa;
  text-transform: uppercase;
  font-size: 1.4rem;
  border: 1px solid #f1f1f1;
}
.select--styled {
  width: 50%;
  min-width: 250px;
}
.select-wrapper {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}
@media only screen and (max-width: 768px) {
  .select-wrapper {
    align-items: flex-start;
    flex-direction: column;
  }
}
.label {
  margin-bottom: 0.5rem;
  padding: 0;
  color: inherit;
  font-size: 1.4rem;
  text-align: left;
}
.label--filter {
  padding-left: 2rem;
  font-weight: 400;
}
.label--styled {
  width: 35%;
  min-width: 250px;
  font-size: 1.4rem;
}
@media only screen and (max-width: 1200px) {
  .label--styled {
    min-width: unset;
  }
}
.section-heading {
  margin-bottom: 3rem;
  text-align: center;
}
.section-heading--left {
  text-align: left;
}
.section-heading--margin-large {
  margin-bottom: 6rem;
}
.section-heading__title {
  font-size: 3rem;
  font-weight: 700;
}
.section-heading__title--light {
  font-weight: 400;
  color: #ffffff;
}
.section-heading__title--color {
  font-weight: 400;
  color: #2c6f87;
}
.section-heading__title--small {
  font-size: 2.5rem;
}
.section-heading__subtitle {
  margin-top: 1rem;
  font-size: 2rem;
}
.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.logo__main {
  position: relative;
  display: block;
  line-height: 1;
  font-size: 2.8rem;
  padding: 2px 1rem;
  font-family: 'Maven Pro', 'Montserrat', sans-serif;
  text-transform: uppercase;
  z-index: 100;
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  .logo__main {
    font-size: 2.25rem;
  }
}
@media only screen and (max-width: 576px) {
  .logo__main {
    font-size: 1.8rem;
  }
}
.logo__sub {
  display: block;
  color: #ffffff;
  padding: 0.5rem 1.5rem;
  font-size: 1.2rem;
  font-family: 'Maven Pro', 'Montserrat', sans-serif;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 3px;
  font-weight: 700;
  line-height: 1;
  background-color: #2c6f87;
  border: 1px solid #2c6f87;
  margin-top: 0.25rem;
  border-radius: 2px;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .logo__sub {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 576px) {
  .logo__sub {
    font-size: 0.75rem;
  }
}
.logo:hover .logo__border--top,
.logo:focus .logo__border--top,
.logo:active .logo__border--top {
  -webkit-animation: snake-horizontal 0.25s ease-in-out forwards;
  -moz-animation: snake-horizontal 0.25s ease-in-out forwards;
  animation: snake-horizontal 0.25s ease-in-out forwards;
}
@-moz-keyframes snake-horizontal {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
    background-color: #2c6f87;
  }
}
@-webkit-keyframes snake-horizontal {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
    background-color: #2c6f87;
  }
}
@keyframes snake-horizontal {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
    background-color: #2c6f87;
  }
}
.logo:hover .logo__border--bottom,
.logo:focus .logo__border--bottom,
.logo:active .logo__border--bottom {
  -webkit-animation: snake-horizontal 0.25s ease-in-out forwards;
  -moz-animation: snake-horizontal 0.25s ease-in-out forwards;
  animation: snake-horizontal 0.25s ease-in-out forwards;
}
@-moz-keyframes snake-horizontal {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
    background-color: #2c6f87;
  }
}
@-webkit-keyframes snake-horizontal {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
    background-color: #2c6f87;
  }
}
@keyframes snake-horizontal {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
    background-color: #2c6f87;
  }
}
.logo:hover .logo__border--left,
.logo:focus .logo__border--left,
.logo:active .logo__border--left {
  -webkit-animation: snake-vertical 0.25s ease-in-out forwards;
  -moz-animation: snake-vertical 0.25s ease-in-out forwards;
  animation: snake-vertical 0.25s ease-in-out forwards;
}
@-moz-keyframes snake-vertical {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
    background-color: #2c6f87;
  }
}
@-webkit-keyframes snake-vertical {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
    background-color: #2c6f87;
  }
}
@keyframes snake-vertical {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
    background-color: #2c6f87;
  }
}
.logo:hover .logo__border--right,
.logo:focus .logo__border--right,
.logo:active .logo__border--right {
  -webkit-animation: snake-vertical 0.25s ease-in-out forwards;
  -moz-animation: snake-vertical 0.25s ease-in-out forwards;
  animation: snake-vertical 0.25s ease-in-out forwards;
}
@-moz-keyframes snake-vertical {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
    background-color: #2c6f87;
  }
}
@-webkit-keyframes snake-vertical {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
    background-color: #2c6f87;
  }
}
@keyframes snake-vertical {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
    background-color: #2c6f87;
  }
}
.logo__border {
  position: absolute;
  display: block;
  background-color: #eccc3a;
  transition: all 200ms ease-in-out;
}
.logo__border--top {
  top: 0;
  left: 0;
  height: 2px;
  width: 100%;
  transform-origin: 100% 0%;
  transform: scaleX(1);
  -webkit-animation: snake-horizontal-back 0.25s ease-in-out forwards;
  -moz-animation: snake-horizontal-back 0.25s ease-in-out forwards;
  animation: snake-horizontal-back 0.25s ease-in-out forwards;
}
@-moz-keyframes snake-horizontal-back {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
@-webkit-keyframes snake-horizontal-back {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes snake-horizontal-back {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
.logo__border--bottom {
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  transform-origin: 0% 0%;
  transform: scaleX(1);
  -webkit-animation: snake-horizontal-back 0.25s ease-in-out forwards;
  -moz-animation: snake-horizontal-back 0.25s ease-in-out forwards;
  animation: snake-horizontal-back 0.25s ease-in-out forwards;
}
@-moz-keyframes snake-horizontal-back {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
@-webkit-keyframes snake-horizontal-back {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes snake-horizontal-back {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
.logo__border--left {
  bottom: 0;
  left: 0;
  height: 100%;
  width: 2px;
  transform-origin: 50% 100%;
  transform: scaleY(0);
  -webkit-animation: snake-vertical-back 0.25s ease-in-out forwards;
  -moz-animation: snake-vertical-back 0.25s ease-in-out forwards;
  animation: snake-vertical-back 0.25s ease-in-out forwards;
}
@-moz-keyframes snake-vertical-back {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}
@-webkit-keyframes snake-vertical-back {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}
@keyframes snake-vertical-back {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}
.logo__border--right {
  bottom: 0;
  right: 0;
  height: 100%;
  width: 2px;
  transform-origin: 100% 0%;
  transform: scaleY(0);
  -webkit-animation: snake-vertical-back 0.25s ease-in-out forwards;
  -moz-animation: snake-vertical-back 0.25s ease-in-out forwards;
  animation: snake-vertical-back 0.25s ease-in-out forwards;
}
@-moz-keyframes snake-vertical-back {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}
@-webkit-keyframes snake-vertical-back {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}
@keyframes snake-vertical-back {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}
.tabpanel__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
@media only screen and (max-width: 1200px) {
  .tabpanel__list .product-card--four-grid:nth-of-type(4) {
    display: none;
  }
}
@media only screen and (max-width: 992px) {
  .tabpanel__list .product-card--four-grid:nth-of-type(4) {
    display: flex;
  }
}
@media (max-width: 576px) {
  .tab-content {
    height: 100vh;
  }
}
.tabs-promo__heading {
  margin-bottom: 2rem;
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
}
.nav.nav-tabs {
  text-align: center;
  border-bottom: none;
}
.nav.nav-tabs li {
  float: none;
  display: inline-block;
}
.nav.nav-tabs li.active a {
  color: #eccc3a;
  border: none;
}
.nav.nav-tabs li a {
  font-size: 1.6rem;
}
.nav.nav-tabs li a:hover,
.nav.nav-tabs li a:active,
.nav.nav-tabs li a:focus {
  color: #eccc3a;
  background-color: initial;
  border-color: transparent;
}
.products-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.products-grid.list-horizontal .product-card {
  width: 100%;
  height: auto;
  flex-direction: row;
}
.products-grid.list-horizontal .product-card__image-wrapper {
  max-width: 300px;
  flex-shrink: 0;
}
@media only screen and (max-width: 576px) {
  .products-grid.list-horizontal .product-card__image-wrapper {
    max-width: 150px;
    flex-shrink: 1;
  }
}
.products-grid.list-horizontal .product-card__content {
  display: flex;
}
@media only screen and (max-width: 1200px) {
  .products-grid.list-horizontal .product-card__content {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
.products-grid.list-horizontal .product-card__header {
  width: 50%;
}
@media only screen and (max-width: 1200px) {
  .products-grid.list-horizontal .product-card__header {
    width: 100%;
  }
}
.products-grid.list-horizontal .product-card__price-wrapper {
  width: 25%;
}
@media only screen and (max-width: 1200px) {
  .products-grid.list-horizontal .product-card__price-wrapper {
    width: 100%;
  }
}
.products-grid.list-horizontal .product-card__actions {
  width: 25%;
}
@media only screen and (max-width: 1200px) {
  .products-grid.list-horizontal .product-card__actions {
    width: 100%;
  }
}
@media only screen and (max-width: 1200px) {
  .products-grid.list-horizontal .product-card__actions .btn {
    margin: 0 auto;
  }
}
.products-grid.list-horizontal .product-card__new-label {
  top: -2.5rem;
  right: unset;
  left: 0;
}
@media only screen and (max-width: 576px) {
  .products-grid.list-horizontal .product-card__new-label {
    top: -1.5rem;
    right: unset;
    left: -3.5rem;
  }
}
.products-grid.list-horizontal .product-card__promo-label {
  top: 12.5%;
  left: unset;
  right: 45%;
  transform: rotate(-37.5deg);
}
@media only screen and (max-width: 576px) {
  .products-grid.list-horizontal .product-card__promo-label {
    top: 14.5%;
    left: unset;
    right: 40%;
  }
}
.products-view-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagination-list {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  margin-top: 2rem;
  flex-wrap: wrap;
}
.pagination-list__item {
  padding: 0.5rem 0.5rem;
}
.pagination-list__item.active .pagination-list__link {
  color: #ffffff;
  background-color: #2c6f87;
}
.pagination-list__link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 3rem;
  font-weight: 700;
  color: #2c6f87;
  border: 1px solid #2c6f87;
  border-radius: 50%;
  transition: all 250ms ease;
}
.pagination-list__link:hover {
  color: #ffffff;
  background-color: #2c6f87;
}
.grid-mode-switch {
  display: flex;
  justify-content: center;
  align-items: center;
}
.grid-mode-grid,
.grid-mode-list {
  margin: 0 0.5rem;
  font-size: 1.75rem;
  background-color: transparent;
  border: none;
  border-radius: 2px;
  opacity: 0.25;
}
.grid-mode-grid.active,
.grid-mode-list.active {
  opacity: 1;
}
.category-nav {
  margin-left: 2rem;
  list-style-type: circle;
}
.category-nav__title {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.8rem;
}
.category-nav__subtitle {
  margin-top: 2rem;
  font-size: 1.6rem;
}
.category-nav__item {
  text-transform: uppercase;
}
.category-nav__item.active {
  font-weight: 700;
  color: #2c6f87;
}
.category-nav__item.active .collapse-list {
  display: block;
}
.category-nav__item.active .collapse-list > .category-nav__item {
  font-size: 1.2rem;
  font-weight: 400;
  color: #23292c;
}
.category-nav .category-nav__link {
  position: relative;
  display: inline-block;
  padding: 0.75rem 0;
  border-bottom: 1px solid transparent;
}
.category-nav .category-nav__link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 0;
  height: 2px;
  background-color: #eccc3a;
  transition: width 250ms ease-in-out;
}
.category-nav .category-nav__link:hover {
  background-color: initial;
}
.category-nav .category-nav__link:hover::after {
  width: 100%;
}
.collapse-list {
  display: none;
  margin-left: 1rem;
  list-style-type: circle;
}
.filter-form {
  border-top: 2px solid #f1f1f1;
  border-bottom: 2px solid #f1f1f1;
  padding-bottom: 1rem;
}
.filter-form .div {
  margin-bottom: 1rem;
}
.filter-form .btn {
  margin-top: 0.5rem;
}
.search-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0.5rem;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}
@media (max-width: 576px) {
  .search-form {
    padding: 0;
  }
}
@media only screen and (max-width: 576px) {
  .search-form {
    flex-direction: column;
  }
}
.search-form__input {
  padding: 1rem;
  margin-right: 1rem;
  background-color: #ffffff;
}
@media only screen and (max-width: 576px) {
  .search-form__input {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
}
.search-form__button {
  padding: 0.75rem;
  font-weight: 400;
}
.search-form__button img {
  margin-right: 0.5rem;
  width: 1.25rem;
  margin-top: -0.5rem;
}
@media only screen and (max-width: 576px) {
  .search-form__button {
    width: 100%;
  }
}
.search-form .btn-icon {
  margin-left: 1rem;
}
.image {
  display: block;
}
.image--center {
  margin: 0 auto;
}
.image--25 {
  width: 25%;
}
.image--33 {
  width: 33.33%;
}
.image--50 {
  width: 50%;
}
.image--66 {
  width: 66.66%;
}
.image--75 {
  width: 75%;
}
.image--100 {
  width: 100%;
}
@media only screen and (max-width: 576px) {
  .image--25-sm {
    width: 25%;
  }
}
.image--33-sm .screen-sm {
  width: 33.33%;
}
@media only screen and (max-width: 576px) {
  .image--50-sm {
    width: 50%;
  }
}
@media only screen and (max-width: 576px) {
  .image--66-sm {
    width: 66.66%;
  }
}
@media only screen and (max-width: 576px) {
  .image--75-sm {
    width: 75%;
  }
}
@media only screen and (max-width: 576px) {
  .image--100-sm {
    width: 100%;
  }
}
.image--mt1 {
  margin-top: 1rem;
}
.image--mt2 {
  margin-top: 2rem;
}
.image--mt3 {
  margin-top: 3rem;
}
.image--mb1 {
  margin-bottom: 1rem;
}
.image--mb2 {
  margin-bottom: 2rem;
}
.image--mb3 {
  margin-bottom: 3rem;
}
.image--rounded {
  border-radius: 5px;
}
.product-card {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  height: 35rem;
  min-width: 270px;
  padding: 1rem;
  margin: 1rem;
  text-align: center;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  box-shadow: 0 0.2rem 0.4rem #f1f1f1;
  overflow: hidden;
  transition: all 250ms ease-in-out;
}
.product-card--hidden {
  height: 0;
  opacity: 0;
  visibility: hidden;
  padding: 0;
  margin: 0;
}
@media only screen and (max-width: 992px) {
  .product-card {
    margin: 0.5rem;
  }
}
@media only screen and (max-width: 768px) {
  .product-card {
    height: 28rem;
    min-width: 150px;
  }
}
@media only screen and (max-width: 576px) {
  .product-card {
    margin: 0.25rem;
  }
}
.product-card:hover,
.product-card:active,
.product-card:focus {
  box-shadow: 0 0.5rem 1rem #f1f1f1;
}
.product-card--full-width {
  width: 100%;
}
.product-card--three-grid {
  width: calc(33.33% - 2rem);
}
@media only screen and (max-width: 1200px) {
  .product-card--three-grid {
    width: calc(50% - 2rem);
  }
}
@media only screen and (max-width: 992px) {
  .product-card--three-grid {
    width: calc(50% - 1rem);
  }
}
@media only screen and (max-width: 768px) {
  .product-card--three-grid {
    width: calc(50% - 1rem);
  }
}
@media only screen and (max-width: 576px) {
  .product-card--three-grid {
    width: calc(50% - .5rem);
  }
}
@media only screen and (max-width: 359px) {
  .product-card--three-grid {
    width: 80%;
    margin: 0.5rem auto;
  }
}
.product-card--four-grid {
  width: calc(25% - 2rem);
}
@media only screen and (max-width: 1200px) {
  .product-card--four-grid {
    width: calc(33.33% - 2rem);
  }
}
@media only screen and (max-width: 992px) {
  .product-card--four-grid {
    width: calc(50% - 1rem);
  }
}
@media only screen and (max-width: 768px) {
  .product-card--four-grid {
    width: calc(50% - 1rem);
  }
}
@media only screen and (max-width: 576px) {
  .product-card--four-grid {
    width: calc(50% - .5rem);
  }
}
@media only screen and (max-width: 359px) {
  .product-card--four-grid {
    width: 80%;
    margin: 0.5rem auto;
  }
}
.product-card--aside {
  margin: 1rem;
  max-width: 325px;
}
@media only screen and (max-width: 576px) {
  .product-card--aside {
    width: 80%;
    margin: 1rem;
  }
}
.product-card__promo-label {
  position: absolute;
  top: 5%;
  left: 37.5%;
  width: 100%;
  padding: 0.75rem 5rem;
  font-weight: 700;
  font-size: 1.2rem;
  color: #ffffff;
  background-color: #f62a66;
  text-align: center;
  text-transform: uppercase;
  transform: rotate(37.5deg);
  z-index: 40;
}
@media only screen and (max-width: 768px) {
  .product-card__promo-label {
    top: 6%;
    font-size: 1.1rem;
    padding: 0.5rem 5rem;
  }
}
@media only screen and (max-width: 576px) {
  .product-card__promo-label {
    top: 5%;
    left: 32.5%;
    font-size: 1rem;
    padding: 0.35rem 5rem;
  }
}
.product-card__image-wrapper {
  position: relative;
  padding: 0 1rem;
  margin: 0 auto;
  max-width: 300px;
  overflow: hidden;
  height: 175px;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .product-card__image-wrapper {
    max-width: 225px;
  }
}
.product-card__image-wrapper:hover .product-card__image-overlay,
.product-card__image-wrapper:active .product-card__image-overlay,
.product-card__image-wrapper:focus .product-card__image-overlay {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}
.product-card__image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  display: block;
  transform: translate(-50%, -50%);
}
.product-card__image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.15);
  transition: transform 250ms ease-in-out, opacity 250ms ease-in-out, visibility 250ms ease-in-out;
}
.product-card__content {
  width: 100%;
}
.product-card__header {
  position: relative;
  width: 100%;
  margin-top: 1.5rem;
}
@media only screen and (max-width: 768px) {
  .product-card__header {
    margin-top: 1rem;
  }
}
@media only screen and (max-width: 576px) {
  .product-card__header {
    margin-top: 0.75rem;
  }
}
.product-card__new-label {
  position: absolute;
  top: -3.5rem;
  right: 0;
  padding: 0.5rem 1rem;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  background-color: #eccc3a;
  border: 1px solid #eccc3a;
  border-radius: 50px;
  box-shadow: 0 0.3rem 0.7rem #f1f1f1;
  z-index: 40;
}
@media only screen and (max-width: 768px) {
  .product-card__new-label {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 576px) {
  .product-card__new-label {
    font-size: 0.9rem;
  }
}
.product-card__name {
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: 700;
}
@media only screen and (max-width: 768px) {
  .product-card__name {
    font-size: 1.4rem;
  }
}
.product-card__category {
  font-size: 1.2rem;
  text-transform: uppercase;
}
.product-card__price-wrapper {
  margin-top: 1.5rem;
}
@media only screen and (max-width: 768px) {
  .product-card__price-wrapper {
    margin-top: 1rem;
  }
}
@media only screen and (max-width: 576px) {
  .product-card__price-wrapper {
    margin-top: 0.5rem;
  }
}
.product-card__price-wrapper--marginless {
  margin: 0;
}
.product-card__price {
  font-size: 2.25rem;
  color: #2c6f87;
}
@media only screen and (max-width: 768px) {
  .product-card__price {
    font-size: 1.8rem;
  }
}
@media only screen and (max-width: 576px) {
  .product-card__price {
    font-size: 1.6rem;
  }
}
.product-card__price--marginless {
  margin: 0;
}
.product-card__currency {
  margin: 0 0.5rem;
  font-size: 1.75rem;
  color: #2c6f87;
  text-transform: uppercase;
}
@media only screen and (max-width: 768px) {
  .product-card__currency {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 576px) {
  .product-card__currency {
    font-size: 1.4rem;
  }
}
.product-card__discounted-price {
  position: relative;
  font-size: 1.5rem;
  color: #838383;
}
@media only screen and (max-width: 768px) {
  .product-card__discounted-price {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 576px) {
  .product-card__discounted-price {
    font-size: 1.2rem;
  }
}
.product-card__discounted-price:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  top: 50%;
  left: 50%;
  background-color: #838383;
  transform: translate(-50%, -50%);
}
.product-card__actions {
  margin-top: 1rem;
}
.product-card__full-w-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.modal-open {
  overflow: hidden;
}
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  position: relative;
  margin: 0 auto;
}
.modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid #23292c;
  border-radius: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0;
}
.modal-header {
  padding: 15px;
  border-bottom: 1px solid #23292c;
}
.modal-header .close {
  margin-top: -2px;
}
.modal-title {
  margin: 0;
  line-height: 1.42857143;
}
.modal-body {
  position: relative;
  padding: 15px;
}
.client-panel {
  display: flex;
  justify-content: flex-end;
}
.client-panel--navbar {
  float: right;
  margin-top: 0.8rem;
  margin-right: 1.6rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 400ms ease-in, visibility 400ms ease-in;
}
.client-panel--navbar.visible {
  opacity: 1;
  visibility: visible;
}
.client-panel__item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  border: 1px solid #f1f1f1;
  background-color: #ffffff;
  box-shadow: 0 0.3rem 0.7rem #f1f1f1;
}
.client-panel__item--navbar {
  background-color: transparent;
}
.client-panel__item--cart:after {
  content: attr(data-quantity);
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  height: 2rem;
  width: 2rem;
  font-size: 1.2rem;
  border-radius: 50%;
  background-color: #fafafa;
  box-shadow: 0 0.3rem 0.3rem #838383;
}
.client-panel__item-desc {
  padding: 0 1rem;
  font-size: 1.2rem;
}
.client-panel__item-desc--navbar {
  display: none;
}
.client-panel__link {
  display: flex;
  justify-content: center;
  align-items: center;
}
.client-panel__icon {
  display: block;
  padding: 1.5rem;
  width: 5rem;
  color: #ffffff;
  border-radius: 2px;
  background-color: #2c6f87;
}
@media only screen and (max-width: 576px) {
  .client-panel__icon {
    padding: 1rem;
    width: 3.5rem;
  }
}
.client-panel__icon--navbar {
  padding: 0.5rem;
  width: 3.25rem;
  height: 3.25rem;
}
@media only screen and (max-width: 576px) {
  .account-link {
    display: none;
  }
}
@media only screen and (max-width: 359px) {
  .account-link {
    display: initial;
  }
}
.nav-bar {
  color: #23292c;
  background-color: #fafafa;
  border: 1px solid #f1f1f1;
  box-shadow: 0 0.3rem 0.7rem #f1f1f1;
  text-transform: uppercase;
  transition: all 250ms ease-in-out;
}
.nav-bar__inner {
  background-color: #fafafa;
}
.main-nav.active .main-nav__list {
  max-height: 800px;
}
.main-nav__list {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: max-height 500ms ease;
}
@media (max-width: 992px) {
  .main-nav__list {
    overflow: hidden;
  }
}
@media only screen and (max-width: 992px) {
  .main-nav__list {
    flex-direction: column;
    margin-top: 5.5rem;
    max-height: 0;
  }
}
.main-nav__item {
  text-align: center;
}
.main-nav__item.dropdown {
  position: relative;
  display: inline-block;
  padding: 15px;
  cursor: pointer;
}
@media (max-width: 992px) {
  .main-nav__item.dropdown {
    display: none;
  }
}
.main-nav__item.dropdown .nav__item-icon-wrapper {
  display: flex;
  align-items: center;
}
.main-nav__item.dropdown .nav__item-icon-wrapper .dropdown-icon {
  height: 8px;
  width: auto;
  margin-left: 5px;
}
.main-nav__item.dropdown .dropdown-content {
  display: none;
  visibility: hidden;
  position: absolute;
  top: 42px;
  padding: 10px 7px;
  background-color: #fafafa;
  z-index: 1;
}
.main-nav__item.dropdown:hover .dropdown-content,
.main-nav__item.dropdown .dropdown-content:hover {
  display: block;
  visibility: visible;
}
.main-nav__item.sm-screen {
  display: none;
}
@media (max-width: 992px) {
  .main-nav__item.sm-screen {
    display: block;
  }
}
.main-nav__item.active {
  color: #2c6f87;
}
@media only screen and (max-width: 992px) {
  .main-nav__item:last-of-type {
    margin-bottom: 2rem;
  }
}
.main-nav__link {
  position: relative;
  display: block;
  line-height: 1.5;
  padding: 1rem 1.5rem;
  font-size: 13px;
}
@media only screen and (max-width: 1366px) {
  .main-nav__link {
    padding: 1rem;
  }
}
.main-nav__link.active {
  color: #2c6f87;
}
.main-nav__link::after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #eccc3a;
  transition: width 250ms ease-in-out;
}
.main-nav__link:hover,
.main-nav__link:active,
.main-nav__link:focus {
  color: #2c6f87;
  background-color: initial;
}
.main-nav__link:hover::after,
.main-nav__link:active::after,
.main-nav__link:focus::after {
  width: 100%;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 0.3rem 0.7rem #838383;
  z-index: 100;
}
.nav-bar__toggle {
  padding: 0.8rem 1.6rem;
  color: #2c6f87;
  border: 1px solid #2c6f87;
  border-radius: 2px;
  background-color: transparent;
  float: right;
  display: none;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
@media only screen and (max-width: 992px) {
  .nav-bar__toggle {
    display: block;
  }
}
.breadcrumb {
  background-color: #fafafa;
  margin: 20px 0;
}
.breadcrumb li.active {
  color: #2c6f87;
}
.main-slider {
  height: 650px;
}
@media only screen and (max-width: 768px) {
  .main-slider {
    height: 375px;
  }
}
@media only screen and (max-width: 576px) {
  .main-slider {
    height: 325px;
  }
}
.main-slider__slide {
  position: relative;
  display: block;
  height: 650px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (max-width: 768px) {
  .main-slider__slide {
    height: 375px;
  }
}
@media only screen and (max-width: 576px) {
  .main-slider__slide {
    height: 325px;
  }
}
.main-slider__slide-content {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 4rem;
  width: 55%;
  background-color: rgba(250, 250, 250, 0.9);
  border-radius: 2px;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 992px) {
  .main-slider__slide-content {
    width: 75%;
    padding: 2rem;
  }
}
@media only screen and (max-width: 768px) {
  .main-slider__slide-content {
    width: 85%;
  }
}
@media only screen and (max-width: 576px) {
  .main-slider__slide-content {
    top: 40%;
  }
}
.main-slider__slide-title {
  font-family: 'Maven Pro', 'Montserrat', sans-serif;
  font-size: 3.75rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
}
@media only screen and (max-width: 768px) {
  .main-slider__slide-title {
    font-size: 2.25rem;
  }
}
.main-slider__slide-para {
  font-size: 1.8rem;
  margin: 10px 0;
}
@media only screen and (max-width: 768px) {
  .main-slider__slide-para {
    font-size: 1.6rem;
  }
}
.main-slider .owl-controls {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -100%);
}
.section-header {
  padding: 1rem 0;
  color: #23292c;
  background-color: #fafafa;
}
.section-header__info-links {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  border-bottom: 1px solid #f1f1f1;
}
.section-header__info-item {
  padding: 0 1rem;
  font-size: 1.4rem;
}
.section-header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 576px) {
  .section-header__inner {
    flex-direction: column;
  }
}
.section-header__inner .banners {
  min-width: 25%;
  width: 25%;
  display: flex;
  align-items: center;
}
.section-header__inner .banners a {
  display: block;
  margin: 0 5px;
}
.section-header__inner .banners a img {
  width: 90%;
  opacity: 0.8;
}
@media only screen and (max-width: 359px) {
  .section-header__logo {
    margin-bottom: 1rem;
  }
}
@media (max-width: 576px) {
  .section-header__client-panel {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 768px) {
  .section-header__client-panel {
    width: auto;
  }
}
.section-header__search {
  width: 35%;
}
@media (max-width: 768px) {
  .section-header .client-panel__icon {
    display: none;
  }
  .section-header .client-panel__item-desc {
    text-align: center;
    font-size: 1.1rem;
    padding: 0 15px;
  }
  .section-header .client-panel__item-desc .account-actions__item,
  .section-header .client-panel__item-desc p {
    padding: 5px 0;
  }
  .section-header .client-panel__item-desc span {
    display: inline-block;
    padding-top: 5px;
  }
}
.recommended-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media only screen and (max-width: 1200px) {
  .recommended-list .product-card--four-grid:nth-of-type(4) {
    display: none;
  }
}
@media only screen and (max-width: 992px) {
  .recommended-list .product-card--four-grid:nth-of-type(4) {
    display: flex;
  }
}
.aside-promo__items-list {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 576px) {
  .aside-promo__items-list {
    justify-content: center;
  }
}
.advantages {
  position: relative;
}
.advantages__inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .advantages__inner {
    flex-direction: column;
  }
}
.advantages__service {
  display: flex;
  align-items: center;
  width: 300px;
  padding: 2rem;
  margin: 0 2rem;
  font-size: 1.6rem;
  border-radius: 2 px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 768px) {
  .advantages__service {
    padding: 1rem 2rem;
    margin: 2rem auto 0;
    width: 65%;
  }
}
@media only screen and (max-width: 576px) {
  .advantages__service {
    width: 85%;
  }
}
.advantages__icon-wrapper {
  padding: 1rem;
  margin-right: 1rem;
  border-radius: 50%;
  background-color: #fafafa;
}
.advantages__icon {
  width: 2.25rem;
  color: #eccc3a;
}
.facebook-plugin {
  position: fixed;
  right: -300px;
  height: 200px;
  top: 35%;
  z-index: 999;
  display: flex;
  align-items: center;
  transition: 0.4s;
}
.facebook-plugin:hover {
  right: 0;
}
.facebook-plugin .plugin-service {
  display: flex;
  align-items: center;
}
.facebook-plugin .plugin-service .service-button {
  cursor: pointer;
}
.facebook-plugin .plugin-service .service-button img {
  height: 45px;
}
.section-footer {
  padding: 3rem 0;
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.6);
  background-color: #23292c;
}
.section-footer__inner {
  display: flex;
  padding: 2rem 0;
}
@media only screen and (max-width: 992px) {
  .section-footer__inner {
    justify-content: center;
    flex-wrap: wrap;
  }
}
.section-footer__item {
  flex: 1;
  padding: 0 1rem;
  margin: 0 1rem 3rem;
  min-width: 200px;
}
.section-footer__item:not(:last-of-type) {
  border-right: 1px solid rgba(255, 255, 255, 0.05);
}
@media only screen and (max-width: 1200px) {
  .section-footer__item:not(:last-of-type) {
    border-right: 0px;
  }
}
@media only screen and (max-width: 1200px) {
  .section-footer__item {
    text-align: center;
  }
}
.section-footer__heading {
  margin-bottom: 2rem;
  font-size: 1.4rem;
  font-weight: 700;
  color: #ffffff;
}
.section-footer__heading:nth-of-type(2) {
  margin-top: 2rem;
}
@media only screen and (max-width: 576px) {
  .section-footer__heading {
    font-size: 1.5rem;
  }
}
.section-footer__list-item {
  margin-top: 1rem;
}
.section-footer__link {
  color: rgba(255, 255, 255, 0.6);
}
.section-footer__link:hover,
.section-footer__link:active,
.section-footer__link:focus {
  color: #ffffff;
}
.section-footer__address p {
  margin-top: 0.5rem;
}
.content-page__cta {
  background-color: #fafafa;
  padding: 50px 0;
}
.content-page__cta.bg-white {
  background-color: #fff;
}
.content-page__cta .cta__inner {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.content-page__cta .cta__inner p {
  font-size: 2.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 2rem;
  line-height: 40px;
  text-align: center;
}
@media (max-width: 576px) {
  .content-page__cta .cta__inner p {
    font-size: 2rem;
    line-height: 38px;
  }
}
.content-page__cta .cta__inner p span {
  color: #2c6f87;
  font-weight: 700;
  font-size: 4rem;
}
.content-page__cta .cta__inner .phone {
  color: #2c6f87;
  font-weight: 700;
  font-size: 3.3rem;
}
.section-cta {
  padding-top: 6rem;
  background-color: #fafafa;
}
.section-cta__inner {
  display: flex;
  justify-content: ccenter;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 50px;
}
.cta-card {
  position: relative;
  display: block;
  height: 250px;
  min-width: 280px;
  padding: 2rem;
  margin: 1rem;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 0.7rem 1.5rem rgba(0, 0, 0, 0.25);
  flex: 1;
}
.cta-card__title {
  margin-top: 12rem;
  font-size: 1.8rem;
  color: #fff;
  text-align: center;
  font-weight: 600;
}
.cta-card__action-bar {
  position: absolute;
  top: 2rem;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  font-size: 1.6rem;
  text-transform: uppercase;
  text-align: right;
  color: #ffffff;
  background-image: linear-gradient(to right, transparent, #2c6f87);
  transition: color 200ms ease-in-out;
}
.cta-card__action-bar .icon {
  width: 60px;
  border-radius: 10px;
}
.cta-card:hover .cta-card__action-bar,
.cta-card:active .cta-card__action-bar,
.cta-card:focus .cta-card__action-bar {
  color: #eccc3a;
}
.product-view {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 768px) {
  .product-view {
    flex-direction: column;
  }
}
.product-view__gallery {
  width: 47.5%;
  flex-direction: column;
}
@media only screen and (max-width: 768px) {
  .product-view__gallery {
    width: 80%;
    margin: 0 auto;
  }
}
.product-view__icons {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
}
.product-view__icons img {
  width: 70px;
  margin: 3px;
  cursor: pointer;
}
.product-view__icons img.hidden {
  visibility: hidden;
}
@media (max-width: 576px) {
  .product-view__icons img {
    width: 50px;
  }
}
.product-view__icons .tooltip.top .tooltip-inner {
  background-color: #2c6f87;
  padding: 10px;
}
.product-view__icons .tooltip.top .tooltip-arrow {
  border-top-color: #2c6f87;
}
.product-view__base-info {
  width: 47.5%;
}
@media only screen and (max-width: 768px) {
  .product-view__base-info {
    width: 100%;
  }
}
.product-view__details {
  display: flex;
  justify-content: space-between;
  min-height: 300px;
  padding: 3rem 0;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
}
@media only screen and (max-width: 768px) {
  .product-view__details {
    flex-direction: column;
  }
}
.product-view__heading {
  font-size: 2.25rem;
  font-weight: 700;
}
.product-view__heading--small {
  font-size: 1.6rem;
}
.product-header {
  margin: 2rem 0;
}
.product-header__product-name {
  font-size: 2.5rem;
  font-weight: 700;
}
.product-header__details {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}
.product-header__code {
  margin-right: 2rem;
}
.product-attributes {
  margin-top: 2rem;
}
.product-attributes__list {
  margin-top: 0.5rem;
}
.product-attributes__item {
  margin-top: 1.2rem;
  font-size: 1.4rem;
}
.product-attributes .circle-to-checked {
  display: flex;
  align-items: center;
}
.product-attributes .circle-to-checked .circle {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 3px;
}
.product-attributes .circle-to-checked .circle:last-of-type {
  margin-left: 0;
}
.product-attributes .circle-to-checked .circle.color {
  background: #2c6f87;
}
.product-attributes .circle-to-checked .circle.empty {
  border: 1px solid #2c6f87;
}
.product-variants {
  margin-top: 2rem;
}
.price-wrapper {
  margin: 2rem 0;
  display: flex;
  align-items: center;
}
.price {
  font-size: 2.5rem;
  font-weight: 700;
  color: #2c6f87;
  margin-left: 8px;
}
@media (max-width: 576px) {
  .price {
    font-size: 2.3rem;
  }
}
.price__label {
  font-size: 1.6rem;
}
.price__currency {
  font-size: 1.8rem;
}
.price__netto {
  font-size: 1.5rem;
  margin-left: 8px;
}
.price__netto span {
  font-size: 1.2rem;
}
.price__discounted-price {
  position: relative;
  font-size: 2rem;
  color: #838383;
}
.price__discounted-price::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #838383;
  transform-origin: 0 0;
  transform: translateY(-50%);
}
.price__currency-discounted {
  font-size: 1.5rem;
}
.add-to-cart {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.availibility {
  margin-top: 1rem;
  color: #838383;
}
.availibility__status.availible {
  color: #00A478;
}
.availibility__status.not-availible {
  color: #f62a66;
}
.quantity-selector {
  display: flex;
  align-items: center;
}
.product-description {
  width: 70%;
}
@media only screen and (max-width: 768px) {
  .product-description {
    width: 100%;
  }
}
.product-description__content {
  margin-top: 2rem;
  line-height: 1.5;
}
.product-aside {
  width: 26%;
  padding: 1rem;
  background-color: #fafafa;
  border: 1px solid #f1f1f1;
  box-shadow: 0 0.3rem 0.7rem #f1f1f1;
}
@media only screen and (max-width: 768px) {
  .product-aside {
    width: 80%;
    margin: 0 auto;
    margin-top: 3rem;
  }
}
.product-aside__title {
  margin-bottom: 2rem;
  font-size: 1.8rem;
  text-align: center;
  text-transform: uppercase;
}
.attachment-list {
  list-style: none;
}
.attachment-list__link {
  position: relative;
  display: block;
  margin-top: 1rem;
  margin-left: 3rem;
  color: #2c6f87;
}
.attachment-list__link::before {
  content: '\21D3';
  position: absolute;
  top: 50%;
  left: -1.5rem;
  transform: translate(-50%, -50%);
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 700;
  color: #ffffff;
  background-color: #2c6f87;
  border-radius: 50%;
}
.products__inner {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 768px) {
  .products__inner {
    flex-direction: column;
  }
}
.products__sidebar {
  width: 20%;
  min-width: 225px;
  margin-right: 1rem;
}
@media only screen and (max-width: 768px) {
  .products__sidebar {
    width: 100%;
  }
}
.products .filtering-toggle {
  display: none;
}
.products .filtering-toggle img {
  width: 1.5rem;
  margin-left: 0.5rem;
}
@media only screen and (max-width: 768px) {
  .products .filtering-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.products__sidebar-content {
  transition: max-height 750ms ease-in-out;
}
@media only screen and (max-width: 768px) {
  .products__sidebar-content {
    max-height: 0;
    overflow: hidden;
  }
}
@media only screen and (max-width: 768px) {
  .products__sidebar-content.active {
    max-height: 650px;
    overflow-y: auto;
  }
}
.products__sidebar-item {
  margin-top: 2rem;
}
.products__content {
  width: 75%;
}
@media only screen and (max-width: 768px) {
  .products__content {
    width: 100%;
  }
}
.sorting {
  width: 100%;
  display: flex;
  padding: 1rem 0;
}
.sorting__title {
  margin-right: 2rem;
}
@media only screen and (max-width: 768px) {
  .sorting__title {
    display: none;
  }
}
.sorting__option {
  padding: 0 1rem;
  opacity: 0.5;
}
.sorting__option.active {
  opacity: 1;
}
.sorting__link {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sorting__link img {
  margin-right: 0.25rem;
  width: 1.5rem;
}
@media only screen and (max-width: 576px) {
  .sorting__link {
    font-size: 1rem;
  }
}
.sidebar-categories {
  background-color: #fafafa;
}
.filter__dimensions .label {
  padding: 0.5rem 0.5rem 1rem 0;
}
.filter__dimensions .input {
  margin-top: 0.5rem;
}
@media only screen and (max-width: 992px) {
  .filter__dimensions .input {
    max-width: 200px;
  }
}
@media only screen and (max-width: 576px) {
  .filter__dimensions .input {
    max-width: unset;
  }
}
.filter-list__item {
  position: relative;
  padding: 0.5rem;
}
.contact {
  margin-bottom: 6rem;
}
.contact__form-wrapper {
  margin-bottom: 3rem;
}
.contact__form {
  width: 70%;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .contact__form {
    width: 90%;
  }
}
.contact__form .label,
.contact__form .input {
  display: block;
  width: 100%;
}
.contact__form .label {
  margin-bottom: 0;
  margin-top: 2rem;
  font-size: 1.2rem;
}
.contact__form .btn {
  margin-top: 2rem;
}
.contact__location {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .contact__location {
    flex-direction: column;
    margin-top: 6rem;
  }
}
.contact__data {
  width: 30%;
}
@media only screen and (max-width: 768px) {
  .contact__data {
    width: 100%;
  }
}
.contact__map-wrapper {
  width: 66%;
}
@media only screen and (max-width: 768px) {
  .contact__map-wrapper {
    width: 100%;
    margin-top: 3rem;
  }
}
.contact__map {
  width: 75%;
  height: 400px;
  border: none;
}
@media only screen and (max-width: 768px) {
  .contact__map {
    width: 90%;
  }
}
.contact__title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  color: #2c6f87;
  text-transform: capitalize;
}
.contact__title--sub {
  font-size: 1.8rem;
}
.contact__address {
  margin-top: 2rem;
  font-size: 1.6rem;
}
.contact__address p {
  margin-top: 0.5rem;
}
.contact__forms {
  margin: 2rem 0;
}
.contact__form {
  margin-top: 1rem;
}
.contact__form a {
  font-size: 1.6rem;
}
.content-page__inner {
  display: flex;
  justify-content: space-between;
  text-align: justify;
}
.content-page__inner.flex-column {
  flex-direction: column;
}
.content-page__inner .buttons-wrapper {
  padding: 25px 0;
}
.content-page__inner .buttons-wrapper.btn-next {
  display: flex;
  justify-content: flex-end;
}
.content-page__inner .buttons-wrapper.btn-see-all {
  display: flex;
  justify-content: center;
}
.content-page__inner .buttons-wrapper.btn-next-back {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .content-page__inner .buttons-wrapper.btn-next-back .btn {
    padding: 1rem;
  }
}
@media only screen and (max-width: 992px) {
  .content-page__inner {
    flex-direction: column;
  }
}
.content-page__content {
  width: 75%;
}
@media (min-width: 576px) {
  .content-page__content.pl-15 {
    padding-left: 15px;
  }
}
@media only screen and (max-width: 1366px) {
  .content-page__content {
    width: 70%;
  }
}
@media only screen and (max-width: 992px) {
  .content-page__content {
    width: 100%;
  }
}
.content-page .content-page__recommended {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.content-page .content-page__recommended .recommended-heading {
  margin-bottom: 3rem;
  text-align: center;
}
.content-page .content-page__recommended .recommended-heading h2 {
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
}
.content-page__aside {
  width: 20%;
}
.content-page__aside.w-100 {
  width: 100%;
}
@media only screen and (max-width: 1366px) {
  .content-page__aside {
    width: 25%;
  }
}
@media only screen and (max-width: 992px) {
  .content-page__aside {
    width: 100%;
    margin-top: 6rem;
  }
}
.content-page__image-right {
  float: right;
  width: 55%;
  margin: 0 0 3rem 3rem;
  display: block;
  margin-bottom: 3rem;
  border-radius: 5px;
}
@media only screen and (max-width: 576px) {
  .content-page__image-right {
    width: 100%;
  }
}
.content-page__image-left {
  float: left;
  width: 45%;
  margin: 3rem 3rem 3rem 0;
  display: block;
  border-radius: 10px;
}
@media only screen and (max-width: 768px) {
  .content-page__image-left {
    width: 65%;
  }
}
@media only screen and (max-width: 576px) {
  .content-page__image-left {
    width: 100%;
  }
}
.content-page p {
  margin-top: 2rem;
  line-height: 1.75;
}
.policy__content {
  width: 75%;
}
@media only screen and (max-width: 768px) {
  .policy__content {
    width: 100%;
  }
}
.policy__content--full-w {
  width: 100%;
}
.input-number-decrement,
.input-number-increment {
  background-color: #2c6f87;
}
.system-alerts {
  position: fixed;
  width: 100%;
  z-index: 225;
  top: 0;
}
@media screen and (max-width: 1100px) {
  .navbar li a {
    padding: 15px 10px 15px 10px;
  }
}
@media screen and (max-width: 1280px) {
  .navbar-nav li:nth-of-type(8) {
    display: none;
  }
}
