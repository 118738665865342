.section-header {
  padding: 1rem 0;
  color: @text-dark;
  background-color: @color-ternary;

  &__info-links {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    margin-bottom: 1rem;
    border-bottom: 1px solid @color-grey-light;
  }

  &__info-item {
    padding: 0 1rem;
    font-size: 1.4rem;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .screen-custom(576px, {
      flex-direction: column;
    }

    );

    .banners {
      min-width: 25%;
      width: 25%;
      display: flex;
      align-items: center;

      a {
        display: block;
        margin: 0 5px;

        img {
          width: 90%;
          opacity: 0.8;
        }
      }
    }
  }

  &__logo {
    .screen-custom(359px, {
      margin-bottom: 1rem;
    }

    );
  }

  &__client-panel {
    // width: 100%;

    @media (max-width: 576px) {
      margin-top: 15px;
    }

    .screen-md( {
	  width: auto;

	}

    );
  }

  &__search {
    width: 35%;
  }

  @media (max-width: 768px) {
    .client-panel__icon {
      display: none;
    }
    .client-panel__item-desc {
      text-align: center;
      font-size: 1.1rem;
      padding: 0 15px;
      .account-actions__item,
      p {
        padding: 5px 0;
      }
      span {
        display: inline-block;
        padding-top: 5px;
      }
    }
  }
}

// .account-actions {
//   &__list {}

//   &__item {}

//   &__link {}
// }
