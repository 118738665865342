.section-cta {
	padding-top: 6rem;
	background-color: #fafafa;

	&__inner {
		display: flex;
		justify-content: ccenter;
		align-items: center;
		flex-wrap: wrap;
		padding-bottom: 50px;
	}
}

.cta-card {
	position: relative;
	display: block;
	height: 250px;
	min-width: 280px;
	padding: 2rem;
	margin: 1rem;
	overflow: hidden;
	border-radius: 5px;
	box-shadow: 0 0.7rem 1.5rem rgba(0, 0, 0, 0.25);
	flex: 1;

	//   .screen-md( {
	//     height: 200px;
	//   }

	//   );

	&__title {
		margin-top: 12rem;
		font-size: 1.8rem;
		color: #fff;
		text-align: center;
		font-weight: 600;
	}

	&__action-bar {
		position: absolute;
		top: 2rem;
		right: 0;
		left: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1rem;
		font-size: 1.6rem;
		text-transform: uppercase;
		text-align: right;
		color: @text-white;
		background-image: linear-gradient(to right, transparent, @color-primary);
		transition: color 200ms ease-in-out;

		.icon {
			width: 60px;
			border-radius: 10px;
		}
	}

	.on-event( {
			.cta-card__action-bar {
				color: @color-secondary;
			}
		}

	);
}