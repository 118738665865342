.advantages {
	position: relative;
  
	&__inner {
	  display: flex;
	  justify-content: center;
	  align-items: center;
  
	  .screen-md( {
		flex-direction: column;
	  }
  
	  );
  
	}
  
	&__service {
	  display: flex;
	  align-items: center;
	  width: 300px;
	  padding: 2rem;
	  margin: 0 2rem;
	  font-size: 1.6rem;
	  border-radius: 2 px;
      background-color: @color-white;
      border: 1px solid rgba(#000, 0.2);
	  .screen-md( {
		padding: 1rem 2rem;
		margin: 2rem auto 0;
		width: 65%;
	  }
  
	  );
  
	  .screen-sm( {
		width: 85%;
	  }
  
	  );
	}
  
	&__icon-wrapper {
	  padding: 1rem;
	  margin-right: 1rem;
	  border-radius: 50%;
	  background-color: @color-ternary;
	}
  
	&__icon {
	  width: 2.25rem;
	  color: @color-secondary;
	}
  }