.category-nav {
  margin-left: 2rem;
  list-style-type: circle;

  &__title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.8rem;
  }

  &__subtitle {
    margin-top: 2rem;
    font-size: 1.6rem;
  }

  &__item {
    text-transform: uppercase;

    &.active {
      font-weight: 700;
      color: @color-primary;

      .collapse-list {
        display: block;

        >.category-nav__item {
          font-size: 1.2rem;
          font-weight: 400;
          color: @text-dark;
        }
      }
    }
  }

  .category-nav__link {
    position: relative;
    display: inline-block;
    padding: 0.75rem 0;
    border-bottom: 1px solid transparent;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 0;
      height: 2px;
      background-color: @color-secondary;
      transition: width 250ms ease-in-out;
    }

    &:hover {
      background-color: initial;

      &::after {
        width: 100%;
      }
    }
  }
}

.collapse-list {
  display: none;
  margin-left: 1rem;
  list-style-type: circle;
}


.filter-form {
  border-top: 2px solid @color-grey-light;
  border-bottom: 2px solid @color-grey-light;
  padding-bottom: 1rem;

  .div {
    margin-bottom: 1rem;
  }

  .btn {
    margin-top: .5rem;

  }
}