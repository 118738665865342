.image {
  display: block;

  &--center {
    margin: 0 auto;
  }

  &--25 {
    width: 25%;
  }

  &--33 {
    width: 33.33%;
  }

  &--50 {
    width: 50%;
  }

  &--66 {
    width: 66.66%;
  }

  &--75 {
    width: 75%;
  }

  &--100 {
    width: 100%;
  }

  &--25-sm {
    .screen-sm ( {
      width: 25%;

    }

    );
  }

  &--33-sm {
    .screen-sm {
      width: 33.33%;

    }
  }

  &--50-sm {
    .screen-sm ( {
      width: 50%;

    }

    );
  }

  &--66-sm {
    .screen-sm ( {
      width: 66.66%;

    }

    );
  }

  &--75-sm {
    .screen-sm ( {
      width: 75%;

    }

    );
  }

  &--100-sm {
    .screen-sm ( {
      width: 100%;

    }

    );
  }






  &--mt1 {
    margin-top: 1rem;
  }

  &--mt2 {
    margin-top: 2rem;
  }

  &--mt3 {
    margin-top: 3rem;
  }

  &--mb1 {
    margin-bottom: 1rem;
  }

  &--mb2 {
    margin-bottom: 2rem;
  }

  &--mb3 {
    margin-bottom: 3rem;
  }

  &--rounded {
    border-radius: 5px;
  }
}