.product-card {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  height: 35rem;
  min-width: 270px;
  padding: 1rem;
  margin: 1rem;
  text-align: center;
  border: 1px solid @color-grey-light;
  border-radius: 2px;
  box-shadow: 0 0.2rem 0.4rem @color-grey-light;
  overflow: hidden;
  transition: all 250ms ease-in-out;

  &--hidden {
    height: 0;
    opacity: 0;
    visibility: hidden;
    padding: 0;
    margin: 0;
  }

  .screen-lg( {
      margin: .5rem;
    }

  );

  .screen-md( {
      height: 28rem;
      min-width: 150px;
    }

  );

  .screen-sm( {
      margin: .25rem;
    }

  );

  .on-event( {
      box-shadow: @shadow-medium @color-grey-light;
    }

  );


  &--full-width {
    width: 100%;
  }

  &--three-grid {
    width: calc(~"33.33% - 2rem");

    .screen-xl( {
        width: calc(~"50% - 2rem");
      }

    );

    .screen-lg( {
        width: calc(~"50% - 1rem");
      }

    );

    .screen-md( {
        width: calc(~"50% - 1rem");
      }

    );

    .screen-sm( {
        width: calc(~"50% - .5rem");
      }

    );

    .screen-custom(359px, {
        width: 80%;
        margin: .5rem auto;
      }

    );

  }

  &--four-grid {
    width: calc(~"25% - 2rem");

    .screen-xl( {
        width: calc(~"33.33% - 2rem");
      }

    );

    .screen-lg( {
        width: calc(~"50% - 1rem");
      }

    );

    .screen-md( {
        width: calc(~"50% - 1rem");
      }

    );

    .screen-sm( {
        width: calc(~"50% - .5rem");
      }

    );

    .screen-custom(359px, {
        width: 80%;
        margin: .5rem auto;
      }

    );
  }

  &--aside {
    margin: 1rem;
    max-width: 325px;

    .screen-sm( {
        width: 80%;
        margin: 1rem;
      }

    );
  }

  &__promo-label {
    position: absolute;
    top: 5%;
    left: 37.5%;
    width: 100%;
    padding: 0.75rem 5rem;
    font-weight: 700;
    font-size: 1.2rem;
    color: @text-white;
    background-color: @color-danger;
    text-align: center;
    text-transform: uppercase;
    transform: rotate(37.5deg);
    z-index: 40;

    .screen-md( {
        top: 6%;
        font-size: 1.1rem;
        padding: 0.5rem 5rem;
      }

    );

    .screen-sm( {
        top: 5%;
        left: 32.5%;
        font-size: 1rem;
        padding: 0.35rem 5rem;
      }

    );

  }

  &__image-wrapper {
    position: relative;
    padding: 0 1rem;
    margin: 0 auto;
    max-width: 300px;
    overflow: hidden;
    height: 175px;
    width: 100%;

    .screen-md( {
        max-width: 225px;
      }

    );

    .on-event( {
        .product-card__image-overlay {
          transform: scale(1);
          opacity: 1;
          visibility: visible;
        }
      }

    );
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    display: block;
    transform: translate(-50%, -50%);
  }

  &__image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: fade(black, 40%);
    opacity: 0;
    visibility: hidden;
    transform: scale(1.15);
    transition:
      transform 250ms ease-in-out,
      opacity 250ms ease-in-out,
      visibility 250ms ease-in-out;
  }

  &__content {
    width: 100%;

  }

  &__header {
    position: relative;
    width: 100%;
    margin-top: 1.5rem;

    .screen-md( {
        margin-top: 1rem;
      }

    );

    .screen-sm( {
        margin-top: .75rem;
      }

    );
  }

  &__new-label {
    position: absolute;
    top: -3.5rem;
    right: 0;
    padding: 0.5rem 1rem;
    color: @text-white;
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    background-color: @color-secondary;
    border: 1px solid @color-secondary;
    border-radius: 50px;
    box-shadow: @shadow-small @color-grey-light;
    z-index: 40;

    .screen-md( {
        font-size: 1rem;
      }

    );

    .screen-sm( {
        font-size: .9rem;

      }

    );
  }

  &__name {
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 700;

    .screen-md( {
        font-size: 1.4rem;
      }

    );
  }

  &__category {
    font-size: 1.2rem;
    text-transform: uppercase;
  }

  &__price-wrapper {
    margin-top: 1.5rem;

    .screen-md( {
        margin-top: 1rem;
      }

    );

    .screen-sm( {
        margin-top: .5rem;

      }

    );

    &--marginless {
      margin: 0;
    }
  }

  &__price {
    font-size: 2.25rem;
    color: @color-primary;

    .screen-md( {
        font-size: 1.8rem;
      }

    );

    .screen-sm( {
        font-size: 1.6rem;
      }

    );



    &--marginless {
      margin: 0;
    }
  }

  &__currency {
    margin: 0 0.5rem;
    font-size: 1.75rem;
    color: @color-primary;
    text-transform: uppercase;

    .screen-md( {
        font-size: 1.5rem;
      }

    );

    .screen-sm( {
        font-size: 1.4rem;
      }

    );

  }

  &__discounted-price {
    position: relative;
    font-size: 1.5rem;
    color: @color-grey;

    .screen-md( {
        font-size: 1.4rem;
      }

    );

    .screen-sm( {
        font-size: 1.2rem;

      }

    );

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      top: 50%;
      left: 50%;
      background-color: @color-grey;
      transform: translate(-50%, -50%);
    }
  }

  &__actions {
    margin-top: 1rem;
  }

  &__full-w-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}