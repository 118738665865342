// Kill the scroll on the body
.modal-open {
  overflow: hidden;
}

// Container that the modal scrolls within
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  position: relative;
  margin: 0 auto;
}

// Actual modal
.modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid @color5;
  border-radius: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0;
}

// Modal header
.modal-header {
  padding: 15px;
  border-bottom: 1px solid @color5;
}
// Close icon
.modal-header .close {
  margin-top: -2px;
}

// Title text within header
.modal-title {
  margin: 0;
  line-height: 1.428571429;
}

// Modal body
.modal-body {
  position: relative;
  padding: 15px;
}
