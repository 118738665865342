.main-slider {
	height: 650px;

	.screen-md( {
			height: 375px;
		}

	);

	.screen-sm( {
			height: 325px;
		}

	);

	&__slide {
		position: relative;
		display: block;
		height: 650px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;

		.screen-md( {
				height: 375px;
			}

		);

		.screen-sm( {
				height: 325px;
			}

		);
	}

	&__slide-content {
		position: absolute;
		top: 50%;
		left: 50%;
		padding: 4rem;
		width: 55%;
		background-color: fade(@color-ternary, 90%);
		border-radius: 2px;
		transform: translate(-50%, -50%);

		.screen-lg( {
				width: 75%;
				padding: 2rem;
			}

		);

		.screen-md( {
				width: 85%;
			}

		);

		.screen-sm( {
				top: 40%;
			}

		);
	}

	&__slide-title {
		font-family: @font-secondary;
		font-size: 3.75rem;
		text-transform: uppercase;
		letter-spacing: 1px;
		margin-bottom: .5rem;


		.screen-md( {
				font-size: 2.25rem
			}

		);
	}

	&__slide-para {
        font-size: 1.8rem;
        margin: 10px 0;

		.screen-md( {
				font-size: 1.6rem;
			}

		);
	}

	.owl-controls {
		position: absolute;
		left: 50%;
		transform: translate(-50%, -100%);
	}
}