.aside-promo {
  &__items-list {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 576px) {
  .aside-promo {
    &__items-list {
      justify-content: center;
    }
  }
}